import { Space, Typography } from 'antd'
import { useContext } from 'react'

import SecondaryButton from '@/components/secondary-button'

import { RevealNftContext } from '../RevealNft.context'

import { RevealIcon } from '@/assets/images/icon'

const RevealButton = () => {
  const { tokenIds, unrevealedTokenIds, setOpenRevealPopUp } =
    useContext(RevealNftContext)

  return (
    <SecondaryButton
      className="h-full w-full"
      onClick={() => setOpenRevealPopUp(true)}
    >
      <Space size={4} className="w-full h-full justify-center items-center">
        <RevealIcon />
        <div>
          <Typography.Text type="success">Reveal Your NFTs </Typography.Text>
          <Typography.Text>{'('}</Typography.Text>
          <Typography.Text>
            {tokenIds.length - unrevealedTokenIds?.length}
          </Typography.Text>
          <Typography.Text>/</Typography.Text>
          <Typography.Text type="secondary">{tokenIds.length}</Typography.Text>
          <Typography.Text>{')'}</Typography.Text>
        </div>
      </Space>
    </SecondaryButton>
  )
}

export default RevealButton
