import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'

import { awsS3Service } from '@/services/aws-s3'

import { QUERY_KEY } from '@/constants'
import { ONE_MINUTE } from '@/constants/date-time.constant'

export const useSeasonMetadata = (seasonId: BigNumber) => {
  return useQuery({
    queryKey: [QUERY_KEY.GET_SEASON_METADATA, seasonId],
    queryFn: () => awsS3Service.getSeasonMetadata(seasonId.str()),
    staleTime: 10 * ONE_MINUTE,
    enabled: !!seasonId,
  })
}
