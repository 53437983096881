import { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { ContractTransactionResponse } from 'ethers'
import BigNumber from 'bignumber.js'
import { useAccount } from 'wagmi'

import eventEmitter from '@/providers/event.provider'

import { useReadEliteContract } from '@/hooks/elite/useReadEliteContract'
import { useTokenIdsOwnedByAddress } from '@/hooks/elite/useTokenIdsOwnedByAddress'
import { useUnrevealedTokenIds } from '@/hooks/elite/useUnrevealedTokenIds'
import { useNft } from '@/hooks/nft-service/nft/useNft'

import { ChainID, SystemEvent } from '@/constants'

type RevealNftContextType = {
  tokenIds: BigNumber[]
  unrevealedTokenIds: BigNumber[]
  openRevealPopUp: boolean
  revealingTokenId?: BigNumber
  nftId?: string
  revealingTx?: ContractTransactionResponse
  recentlyRevealedTokenId?: BigNumber

  setOpenRevealPopUp: (open: boolean) => void
  setRevealingTokenId: (tokenId?: BigNumber) => void
  setRevealingTx: (tx: ContractTransactionResponse) => void
  refetchUnrevealedTokenIds: () => void
  setRecentlyRevealedTokenId: (tokenId: BigNumber) => void
}

export const RevealNftContext = createContext<RevealNftContextType>({
  tokenIds: [],
  unrevealedTokenIds: [],
  openRevealPopUp: false,

  setOpenRevealPopUp: () => {},
  setRevealingTokenId: () => {},
  setRevealingTx: () => {},
  refetchUnrevealedTokenIds: () => {},
  setRecentlyRevealedTokenId: () => {},
})

type RevealNftProviderProps = PropsWithChildren

const RevealNftProvider = ({ children }: RevealNftProviderProps) => {
  const { address } = useAccount()
  const { data: tokenIds, refetch: refetchOwnedTokenIds } =
    useTokenIdsOwnedByAddress(address!)
  const { data: unrevealedTokenIds, refetch: refetchUnrevealedTokenIds } =
    useUnrevealedTokenIds(tokenIds)
  const { contractAddress } = useReadEliteContract()
  const { data: nftInfo } = useNft({
    chainId: ChainID.A8,
    address: contractAddress!,
  })

  const [openRevealPopUp, setOpenRevealPopUp] = useState(false)
  const [revealingTokenId, setRevealingTokenId] = useState<BigNumber>()
  const [revealingTx, setRevealingTx] = useState<ContractTransactionResponse>()
  const [recentlyRevealedTokenId, setRecentlyRevealedTokenId] =
    useState<BigNumber>()

  useEffect(() => {
    const listener = () => refetchOwnedTokenIds()
    eventEmitter.addListener(SystemEvent.BURN_NFTS, listener)
    return () => {
      eventEmitter.removeListener(SystemEvent.BURN_NFTS, listener)
    }
  }, [refetchOwnedTokenIds])

  return (
    <RevealNftContext.Provider
      value={{
        tokenIds,
        unrevealedTokenIds,
        openRevealPopUp,
        revealingTokenId,
        nftId: nftInfo?._id,
        revealingTx,
        recentlyRevealedTokenId,
        setOpenRevealPopUp,
        setRevealingTokenId,
        setRevealingTx,
        refetchUnrevealedTokenIds,
        setRecentlyRevealedTokenId,
      }}
    >
      {children}
    </RevealNftContext.Provider>
  )
}

export default RevealNftProvider
