import { Col, Row, Typography } from 'antd'

import CsrProvider from '@/providers/csr.provider'
import BurnRoundItem from './BurnRoundItem'
import BurnTotal from './BurnTotal'
import Burn from './Burn'

import { useAllSeasonRounds } from '@/hooks/regen721/useAllSeasonRounds'
import { useCurrentSeason } from '@/hooks/system/useCurrentSeason'

import './index.scss'

function BurnRound() {
  const seasonId = useCurrentSeason()
  const { data } = useAllSeasonRounds(seasonId!)

  return (
    <div className="burn-round-container h-[617px] mobile:h-auto overflow-y-hidden mobile:mb-[25px]">
      <Typography.Text className="block text-center text-xl font-semibold !-mb-4 mobile:!mb-0 !mt-[24px] mobile:!mt-3">
        BURN ROUNDS
      </Typography.Text>

      <Row align="top" gutter={[0, 12]}>
        <Col span={24}>
          <Row>
            {data?.map((round, roundIndex) => (
              <Col span={24} key={`${seasonId}-${roundIndex}`}>
                <BurnRoundItem round={round} roundIndex={roundIndex} />
              </Col>
            ))}
          </Row>
        </Col>

        <Col span={24}>
          <BurnTotal />
        </Col>

        <Col span={24} className="px-5 mobile:pb-5">
          <CsrProvider>
            <Burn />
          </CsrProvider>
        </Col>
      </Row>
    </div>
  )
}

export default BurnRound
