import { Fragment, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { useAccount } from 'wagmi'
import { ERC20 } from '@space3/web3'

import { Col, Flex, Row, Typography } from 'antd'

import { LiveIcon } from '@/assets/images/icon'
import Countdown from '@/components/countdown'

import { useRoundWhitelistByUserAddress } from '@/hooks/regen721/useRoundWhitelistByUserAddress'
import { useSeasonMetadata } from '@/hooks/regen721/useSeasonMetadata'
import { useCurrentSeason } from '@/hooks/system/useCurrentSeason'
import { useEthersProvider } from '@/hooks/ether/useEthersProvider'

import { NATIVE_TOKEN } from '@/constants'

import { Round } from '@/types/regen-721-engine.type'

type BurnRoundItemProps = {
  round: Round
  roundIndex: number
}

function BurnRoundItem({ round, roundIndex }: BurnRoundItemProps) {
  const seasonId = useCurrentSeason()
  const { data: seasonMetadata } = useSeasonMetadata(seasonId!)
  const provider = useEthersProvider()
  const [decimals, setDecimals] = useState<bigint | null>(null)
  const [symbol, setSymbol] = useState<string>('')

  const { startTime, endTime } = round

  const title = useMemo(
    () => seasonMetadata?.rounds[roundIndex].title ?? 'Unknown round',
    [seasonMetadata, roundIndex],
  )
  const startedDate = useMemo(
    () => dayjs(startTime.multipliedBy(1000).toNumber()),
    [startTime],
  )
  const endedDate = useMemo(
    () => dayjs(endTime.multipliedBy(1000).toNumber()),
    [endTime],
  )
  const isLive = useMemo(
    () => dayjs().isAfter(startedDate) && dayjs().isBefore(endedDate),
    [endedDate, startedDate],
  )
  const { address } = useAccount()
  const {
    data: [, amount],
    eligible,
    roundWhitelist,
  } = useRoundWhitelistByUserAddress(seasonId!, BigNumber(roundIndex), address)

  const formattedPricePerToken = useMemo(() => {
    if (round.currency === NATIVE_TOKEN) {
      return round.pricePerToken.div(Math.pow(10, 18)).toNumber() + ' ETH'
    }

    if (!decimals) return

    return (
      round.pricePerToken.div(Math.pow(10, Number(decimals))).toNumber() +
      ' ' +
      symbol
    )
  }, [decimals, symbol, round.currency, round.pricePerToken])

  useEffect(() => {
    const fetchTokenDetails = async () => {
      if (round.currency === NATIVE_TOKEN) return

      const erc20Contract = ERC20.connect(round.currency, provider)
      const decimalsValue = await erc20Contract.decimals()
      const symbolValue = await erc20Contract.symbol()
      setDecimals(decimalsValue)
      setSymbol(symbolValue)
    }

    fetchTokenDetails()
  }, [round, provider])

  return (
    <Row
      className="p-5 border-0 border-b border-solid border-white/[0.06] burn-round-item"
      align="middle"
      gutter={[0, { lg: 8, md: 12, sm: 12, xs: 12 }]}
    >
      {isLive && (
        <Col span={24} className="pb-4 mobile:pb-1">
          <div className="inline-flex items-center px-3 py-1.5 gap-1.5 rounded-[3px] bg-white/[0.06]">
            <LiveIcon />
            <Typography.Text className="text-[#C7FF00]">LIVE</Typography.Text>
          </div>
        </Col>
      )}

      <Col lg={12} md={24} sm={24} xs={24}>
        <Flex vertical className="uppercase gap-5 mobile:gap-3">
          <div>
            <Typography.Text
              className="text-[15px] font-semibold"
              type="success"
            >
              {title}
            </Typography.Text>
            <Typography.Text className="text-white/40 text-[15px]">
              {' '}
              |{' '}
            </Typography.Text>
            {eligible ? (
              <Typography.Text className="text-warning text-[15px]">
                Eligible
              </Typography.Text>
            ) : (
              <Typography.Text className="text-[#626576] text-[15px]">
                Not Eligible
              </Typography.Text>
            )}
          </div>

          <Flex align="center" gap={8}>
            {roundWhitelist && roundWhitelist.length > 0 && (
              <Fragment>
                <Typography.Text className="block text-white/90 text-[16px] font-bold">
                  {amount} per wallet
                </Typography.Text>

                <div className="w-[5px] h-[5px] bg-primary rotate-45"></div>
              </Fragment>
            )}

            <Typography.Text className="block text-white/90 text-[16px] font-bold">
              {round.pricePerToken.eq(0) ? 'Free mint' : formattedPricePerToken}
            </Typography.Text>
          </Flex>
        </Flex>
      </Col>

      <Col lg={12} md={24} sm={24} xs={24}>
        <Flex align="center" className="justify-end mobile:justify-center">
          <Countdown endedAt={endedDate} startedAt={startedDate} vertical />
        </Flex>
      </Col>
    </Row>
  )
}

export default BurnRoundItem
