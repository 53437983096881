import { useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import { Flex, Typography } from 'antd'

import CsrProvider from '@/providers/csr.provider'

import { convertTwoDigits } from '@/helpers'

type CountdownProps = {
  startedAt: Dayjs
  endedAt: Dayjs
  vertical?: boolean
}

type CountdownItemProps = {
  label: string
  value: number
}

function CountdownItem({ label, value }: CountdownItemProps) {
  return (
    <Flex
      vertical
      justify="center"
      align="center"
      className="w-[47px] h-[37px] pt-0.5 bg-white/[0.04] backdrop-blur-[6px] rounded-sm countdown-item"
    >
      <Typography.Text className="font-bold text-white font-expanded text-xs countdown-number">
        {convertTwoDigits(value)}
      </Typography.Text>
      <Typography.Text className="text-[10px] text-white/60 countdown-text">
        {label}
      </Typography.Text>
    </Flex>
  )
}

function Countdown({ startedAt, endedAt, vertical = false }: CountdownProps) {
  const [currentTime, setCurrentTime] = useState(dayjs())

  const { days, hours, minutes, seconds, isEnded, label } = useMemo(() => {
    const isLive =
      currentTime.isAfter(startedAt) && currentTime.isBefore(endedAt)
    const label = isLive ? 'Ends in' : 'Starts in'
    const targetTime = isLive ? endedAt : startedAt
    const isEnded = dayjs(targetTime).isBefore(currentTime)

    if (isEnded)
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isEnded,
      }
    const time = dayjs(targetTime).diff(currentTime)
    const days = Math.floor(time / (1000 * 60 * 60 * 24))
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((time / 1000 / 60) % 60)
    const seconds = Math.floor((time / 1000) % 60)

    return { days, hours, minutes, seconds, isEnded, label }
  }, [endedAt, startedAt, currentTime])

  useEffect(() => {
    const timerId = setInterval(() => setCurrentTime(dayjs()), 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [setCurrentTime])

  if (isEnded)
    return (
      <Flex justify="flex-end">
        <Typography.Text className="text-[#A3A5AE]">ENDED</Typography.Text>
      </Flex>
    )

  return (
    <CsrProvider>
      <Flex
        vertical={vertical}
        align="center"
        justify="center"
        gap={12}
        className="countdown-container"
      >
        <Typography.Text className="countdown-title text-[#A3A5AE]">
          {label}
        </Typography.Text>

        <Flex gap={4}>
          <CountdownItem label="Days" value={days} />
          <CountdownItem label="Hours" value={hours} />
          <CountdownItem label="Minutes" value={minutes} />
          <CountdownItem label="Seconds" value={seconds} />
        </Flex>
      </Flex>
    </CsrProvider>
  )
}

export default Countdown
